/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { animateScroll as scroll } from "react-scroll";
// import NaviDropdown from "../Components/NavDropdown/NavDropdown";
import "./Navigation.css";
import logoColor from "../assets/images/logoColor.png";

class NavbarPages extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      transform: false,
      modalShow: false,
    };
  }

  // scrollToTop = () => {
  //   scroll.scrollToTop();
  // };

  render() {
    return (
      <div>
        <Navbar collapseOnSelect fixed="top" expand="lg" className="nav_color">
          <Navbar.Brand eventKey={1}>
            <Link to="/">
              <Image className="logo-img" responsive="true" src={logoColor} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link className={"nav_link_black"}>
                <Link to="/">
                  <span className="nav_spans">Home</span>
                </Link>
              </Nav.Link>
              <Nav.Link
                className={"nav_link_black"}
                href="http://advisorportal.stratcap.com"
                target="_blank"
                rel="noopener noenferrer"
              >
                <span className="nav_spans">Advisor Portal</span>
              </Nav.Link>
              {/* <Nav.Link>
                <NaviDropdown change="nav_link_black" />
              </Nav.Link> */}
              <Nav.Link className={"nav_link_black"}>
                <Link to="/assetspotlights">
                  <span className="nav_spans">Asset Spotlights</span>
                </Link>
              </Nav.Link>
              <Nav.Link className={"nav_link_black"}>
                <Link to="/contactus">
                  <span className="nav_spans">Contact Us</span>
                </Link>
              </Nav.Link>
              <Nav.Link className={"nav_link_black"}>
                <Link to="/managementteam">
                  <span className="nav_spans">Management Team</span>
                </Link>
              </Nav.Link>
              {/* <Nav.Link>
                <Link to="/careers">
                  <span className="nav_spans">Careers</span>
                </Link>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavbarPages;
