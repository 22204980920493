import React from "react";

const Money = (props) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        xmlSpace="preserve"
      >
        {/* <g className="svg_circle"> */}
        <g className={props.circleChange}>
          <path
            className="st0"
            d="M100,32.42c37.26,0,67.58,30.32,67.58,67.58s-30.32,67.58-67.58,67.58S32.42,137.26,32.42,100
		S62.74,32.42,100,32.42 M100,26.42c-40.64,0-73.58,32.94-73.58,73.58s32.94,73.58,73.58,73.58s73.58-32.94,73.58-73.58
		S140.64,26.42,100,26.42L100,26.42z"
          />
        </g>

        <g className={props.imageChange}>
          <path
            className="st0"
            d="M126.82,91.71H98.96v-8.36h27.86V91.71z M126.82,72.73H98.96v8.36h27.86V72.73z M95.57,104.62H67.71v8.36h27.86
	V104.62z M95.57,93.99H67.71v8.36h27.86V93.99z M95.57,83.36H67.71v8.36h27.86V83.36z M142.29,117.81c0,8.54-6.92,15.46-15.46,15.46
	s-15.46-6.92-15.46-15.46c0-8.54,6.92-15.46,15.46-15.46S142.29,109.27,142.29,117.81z M132.44,120.02c0-2.82-1.63-4.23-4.89-4.23
	h-1.45c-1.01,0-1.52-0.4-1.52-1.19c0-0.97,0.89-1.45,2.67-1.45c1.48,0,2.99,0.26,4.53,0.79v-2.9c-1.1-0.35-2.18-0.57-3.23-0.66
	v-2.51h-3.1v2.51c-2.82,0.44-4.22,1.76-4.22,3.96c0,2.99,1.54,4.49,4.62,4.49h1.45c1.1,0,1.65,0.4,1.65,1.19
	c0,0.97-0.75,1.45-2.26,1.45c-1.66,0-3.27-0.26-4.81-0.79v2.9c1.1,0.35,2.29,0.57,3.57,0.66v2.51h3.1v-2.51
	C131.14,123.8,132.44,122.39,132.44,120.02z M126.82,99.17v-5.18H98.96v8.36h17.62C119.49,100.34,123.02,99.17,126.82,99.17z
	 M113.87,104.62H98.96v8.36h10.27C110.01,109.77,111.64,106.9,113.87,104.62z"
          />
        </g>
      </svg>
    </>
  );
};

export default Money;
