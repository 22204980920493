/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
// import scrollToComponent from "react-scroll-to-component";
// import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
// import NaviDropdown from "../Components/NavDropdown/NavDropdown";
import Hero from "../Components/Hero/Hero";
import Blurb from "../Components/Blurb/Blurb";
import Distribution from "../Components/Distribution/Distribution";
// import Leverage from "../Components/Leverage/Leverage";
import "./Navigation.css";

class Navibar extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      transform: false
    };
    this._handleScroll = this._handleScroll.bind(this);
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  _handleScroll = () => {
    if (window.pageYOffset > 0) {
      this.setState({ transform: true });
    } else {
      this.setState({ transform: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll, true);
  }

  render() {
    const logoWhite = `assets/logoWhite.png`;
    const logoColor = `assets/logoColor.png`;

    return (
      <div>
        <Navbar
          collapseOnSelect
          fixed="top"
          expand="lg"
          className={this.state.transform === true ? "nav_color" : "nav_clear"}
        >
          <Navbar.Brand eventKey={1} onClick={this.scrollToTop}>
            <Image
              className="logo-img"
              responsive="true"
              src={this.state.transform === true ? logoColor : logoWhite}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className={this.state.transform === true ? "custom-toggler-black" : "custom-toggler-white"} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link
                className={
                  this.state.transform === true
                    ? "nav_link_black"
                    : "nav_link_white"
                }
                // onClick={event => {
                //   event.preventDefault();
                //   scrollToComponent(this.Leverage, {
                //     offset: -110,
                //     align: "top",
                //     duration: 1200
                //   });
                // }}
                href="http://advisorportal.stratcap.com"
                target="_blank"
                rel="noopener noenferrer"
              >
                Advisor Portal
              </Nav.Link>
              <Nav.Link>
                <Link to="/assetspotlights">
                  <span
                    className={
                      this.state.transform === true
                        ? "nav_link_black"
                        : "nav_link_white"
                    }
                  >
                    Asset Spotlights
                  </span>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/contactus">
                  <span
                    className={
                      this.state.transform === true
                        ? "nav_link_black"
                        : "nav_link_white"
                    }
                  >
                    Contact Us
                  </span>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link to="/managementteam">
                  <span
                    className={
                      this.state.transform === true
                        ? "nav_link_black"
                        : "nav_link_white"
                    }
                  >
                    Management Team
                  </span>
                </Link>
              </Nav.Link>
              {/* <Nav.Link>
                <Link to="/careers">
                  <span
                    className={
                      this.state.transform === true
                        ? "nav_link_black"
                        : "nav_link_white"
                    }
                  >
                    Careers
                  </span>
                </Link>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Hero />
        <Blurb />
        <Distribution />
        {/* <section
          className="leverage"
          ref={section => {
            this.Leverage = section;
          }}
        >
          <Leverage />
        </section> */}
      </div>
    );
  }
}

export default Navibar;
