import React from "react";
import ManagementProfile from "../ManagementProfile/ManagementProfile";
import image from "../../assets/team/RobBruce.jpg";

const RobBruceBio = () => {
  const name = "Robert Bruce";
  const title = "Chief Marketing Officer, StratCap";
  const bio = `Mr. Bruce has over 23 years of diverse financial services sales and marketing experience with emphasis on wholesale distribution and start-up ventures. As one of the first employees at StratCap, he was chartered with building a marketing department to facilitate the sales and distribution of the company’s alternative investment product lineup. Throughout his time at StratCap, he helped to successfully launch 6 different alternative investment products that have raised approximately $3 billion in investor capital.`;

  const bio2 = `Prior to StratCap, Mr. Bruce served as Vice President of Sales & Marketing for a California-based real estate company where he was chartered with building a marketing department to support the capital raising efforts for two real estate funds as well as 2 private accredited investor offerings. In his previous position as Director of Marketing at United Healthcare, he spearheaded the construction of a marketing department to support the broker distribution of the company’s Medicare products – a new venture for the firm at the time. He was instrumental in helping build and support this new distribution channel for United Healthcare that quickly grew to represent 75% of the division’s total sales. Prior to United Healthcare, Mr. Bruce was at MetLife Investors where he served as Vice President of Marketing and was responsible for marketing the company's variable and fixed annuities, life insurance and long-term care insurance products. Mr. Bruce helped to launch this MetLife subsidiary in April of 2001 and helped build and structure a marketing department that today supports one of the financial industry’s leading insurance distribution organizations. Previous to MetLife Investors, Mr. Bruce led marketing and sales programs at AXA, UBS, Merrill Lynch and Bloomberg. His varied product background includes experience with REITs, private placements, annuities, life insurance, long-term care insurance, other alternative investments and Medicare products.`;

  const bio3 = `Mr. Bruce is a native of New Jersey where he received his B.S. degree in Business from Kean University. He holds his FINRA Series 7, 63 and 24 registrations and currently resides in California with his wife and three daughters.`;

  return (
    <ManagementProfile
      name={name}
      title={title}
      image={image}
      bio={bio}
      bio2={bio2}
      bio3={bio3}
    />
  );
};

export default RobBruceBio;
