import React from "react";
import "./JobPages.css";
import scLogo from "../../assets/images/SC.jpg";
import { Row, Col, Container, Image } from "react-bootstrap";
import NavbarPages from "../../Containers/NavbarPages";
import careerHero from "../../assets/images/career_hero.jpg";

const EAA = () => {
  return (
    <>
      <NavbarPages />
      <div className="career_hero_container">
        <div className="career_adjustment_layer">
          <Image src={careerHero} fluid />
        </div>
        <div className="hero_text">Come Work With Us</div>
      </div>
      <Container>
        <Row className="jobs_container">
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Image src={scLogo} className="specialist_logo" />
              </Col>
              <Col
                lg={6}
                sm={12}
                className="specialist_resume_button_container"
              >
                <a
                  className="btn-u"
                  href="mailto:career@scdistributors.com?Subject=Executive%Administrative%20Assistant"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply with Resume
                </a>
              </Col>
            </Row>
            <h2 className="jobs_bold jobs_jobtitle_margin">
              Executive Administrative Assistant
            </h2>
            <p>at StratCap</p>
            <p>Costa Mesa, CA - Sales</p>
            <p>Job Type: Full-time</p>

            {/* <p>This Position Reports To: Mario Pacheco, SVP Finance</p> */}
            <h4 className="jobs_bold">Basic Purpose</h4>
            <p>
              Duties include those described for executive assistant, but the
              position supports the most senior executives. Sensitivity to
              confidential matters is required.
            </p>
            <h4 className="jobs_bold">Executive Assistant Responsibilities</h4>
            <ul>
              <li>
                Act as the point of contact between the executives and
                internal/external clients.
              </li>
              <li>
                Undertake the tasks of receiving calls, take messages and
                routing correspondence for executives.
              </li>
              <li>
                Maintain calendar, arrange meetings and appointments; Provide
                reminders.
              </li>
              <li>
                Use various software, including word processing, spreadsheets,
                databases, and presentation software.
              </li>
              <li>Oversee schedules and calendar.</li>
              <li>Help prepare for meetings.</li>
              <li>Handle requests and queries appropriately.</li>
              <li>Make all travel arrangements.</li>
              <li>Produce reports, presentations and briefs.</li>
              <li>
                Obtain travel folios to produce T&E Reports and submit expenses.{" "}
              </li>
            </ul>
            <h4 className="jobs_bold">Job Requirements</h4>
            <ul>
              <li>Bachelor’s degree preferred</li>
              <li>
                At least 2+ years in a professional organization preferably as
                executive administrative assistant or in other secretarial
                position
              </li>
              <li>Ability to organize a daily workload by priorities.</li>
              <li>In-depth understanding of entire MS Office suite.</li>
              <li>Strong organizational skills and attention to detail.</li>
              <li>
                Proven ability to work independently and balance many tasks
                within tight time constraints.
              </li>
              <li>
                Strong initiative, energetic work habits and dedication to job.
                Diligent and proactive.
              </li>
              <li>Ability to multitask and prioritize daily workload</li>
              <li>High level verbal and written communications skills</li>
              <li>
                Must be able to meet deadlines in a fast-paced quickly changing
                environment.
              </li>
              <li>Discretion and confidentiality is crucial.</li>
            </ul>
            <h4 className="jobs_bold">
              Strategic Capital Management Team Experience
            </h4>
            <p>
              Over 75 years of combined experience in wholesale distribution
              management. Helped raise over $20 billion in equity capital over
              the last 15 years.. Successful in distributing Business
              Development Companies, Non-Traded REITs, Reg D private placement
              offerings and LLC’s .{" "}
            </p>
            <h4 className="jobs_bold">About StratCap</h4>
            <p>
              Strategic Capital’s goal is to be the premier distribution
              platform for select alternative investment products. Our mission
              is to connect investors and financial advisors with a focused
              group of leading alternative investment managers. Through a
              demonstrated financial services wholesale distribution model, we
              deliver these products to financial advisors (and their clients)
              throughout the United States.
            </p>
            <p>
              Our leadership's extensive experience in wholesale distribution,
              combined with our strategy to engage a prominent real estate
              investment banking organization, helped enable us to identify and
              evaluate the capabilities, backgrounds, financial sustainability
              and track records of a wide variety of potential investment
              managers. Finally, our leadership's extensive distribution
              experience and our proprietary SCORE (Strategic Capital Online
              Reporting Engine) technology platform helps us to effectively and
              efficiently track and manage each step of the product delivery
              process for our financial advisor and broker/dealer partners.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EAA;
