import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Animated } from "react-animated-css";
import { BrowserView, MobileView } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Hero.css";

import mainVideo from "../../assets/video/BGCLIP.mp4";
import mobileVideo from "../../assets/video/BGCLIP-MOBILE.mp4";
import mobileHero from "../../assets/images/mobile_hero.jpg";

class Hero extends Component {
  constructor() {
    super();
    this.state = {
      transform: false,
    };
    this._handleScroll = this._handleScroll.bind(this);
  }

  _handleScroll = () => {
    if (window.scrollY > 200) {
      this.setState({ transform: true });
    } else {
      this.setState({ transform: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this._handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._handleScroll);
  }

  render() {
    // const mobileImage = `assets/heroimg.jpg`;
    return (
      <>
        <div className="carousel_container">
          <div className="hero_nomargin">
            <BrowserView>
              <video
                width="100%"
                height="auto"
                preload="preload"
                playsInline
                id="video"
                autoplay="autoplay"
                loop="loop"
                muted="muted"
                className="hero_video"
              >
                <source src={mainVideo} type="video/mp4" />
                {/* <source src="movie.ogg" type="video/ogg" /> */}
                Your browser does not support the video tag.
              </video>
            </BrowserView>
            <MobileView>
              <div className="hero_mobile_container">
                {/* <Image
                  className="hero_mobile_image"
                  src="https://images.adsttc.com/media/images/5b08/b87c/f197/ccb5/4900/00bd/medium_jpg/The_S_02_filter_edit2_06.jpg?1527298139"
                  fluid
                /> */}
                <video
                  width="100%"
                  height="auto"
                  preload="preload"
                  playsInline
                  id="video"
                  autoplay="autoplay"
                  loop="loop"
                  muted="muted"
                  className="hero_mobile_image"
                  poster={mobileHero}
                >
                  <source src={mobileVideo} type="video/mp4" />
                  {/* <source src="movie.ogg" type="video/ogg" /> */}
                  Your browser does not support the video tag.
                </video>
                <div
                  className={
                    this.state.transform === true
                      ? "hero_mobile_arrow_hide"
                      : "hero_mobile_arrow"
                  }
                >
                  <FontAwesomeIcon
                    icon="chevron-down"
                    className="chevron_down"
                  />
                </div>
              </div>
            </MobileView>
            <Row className="hero_text_overlay">
              <Col lg={8} sm={12}>
                <Animated animationIn="fadeInLeft" isVisible={true}>
                  <div className="hero_video_header">
                    <h3 className="h3-responsive hero_video_header_title">
                      StratCap Securities
                    </h3>
                    <p className="hero_video_header_text">
                      StratCap Securities is a premier financial services
                      organization that has raised over $5 billion for a variety
                      of alternative investment offerings. Our goal is to
                      provide financial advisors and their clients access to
                      innovative investment structures and asset classes.
                    </p>
                    <p className="hero_video_header_text_bottom">
                      <i>
                        We currently offer dynamic private investments focused
                        on digital infrastructure assets, through our affiliated
                        product sponsor{" "}
                        <a
                          href="http://stratcap.com/"
                          id="hero-scfm-link"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          StratCap Investment Management.
                        </a>
                      </i>
                    </p>
                  </div>
                </Animated>
              </Col>
            </Row>
            <BrowserView>
              <Row
                className={
                  this.state.transform === true
                    ? "hero_arrow_hide"
                    : "hero_arrow"
                }
              >
                <Col lg={12} sm={12}>
                  <div align={"center"}>
                    {/* <h3 className="h3-responsive">
                      Scroll Down
                    </h3> */}
                    <p className="hero_video_header_text">
                      <FontAwesomeIcon
                        icon="chevron-down"
                        className="chevron_down_browser"
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            </BrowserView>
          </div>
        </div>
      </>
    );
  }
}

export default Hero;
