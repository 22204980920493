import React from "react";
import "./SVGs.css";

const PeopleSVG = (props) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        xmlSpace="preserve"
      >
        <g className={props.circleChange}>
          <path
            className="st0"
            d="M100,32.42c37.26,0,67.58,30.32,67.58,67.58s-30.32,67.58-67.58,67.58S32.42,137.26,32.42,100
		S62.74,32.42,100,32.42 M100,26.42c-40.64,0-73.58,32.94-73.58,73.58s32.94,73.58,73.58,73.58s73.58-32.94,73.58-73.58
		S140.64,26.42,100,26.42L100,26.42z"
          />
        </g>
        <g className={props.imageChange}>
          <path
            className="st0"
            d="M66.63,116.55h17.62v-1.68c0-5.41,2.78-10.19,6.97-12.99c-1.06-5.77-6.1-10.15-12.18-10.15
		c-6.85,0-12.41,5.56-12.41,12.41V116.55z"
          />
          <path
            className="st0"
            d="M133.37,116.55v-12.41c0-6.85-5.56-12.41-12.41-12.41c-6.09,0-11.14,4.4-12.19,10.19
		c4.23,2.81,6.97,7.57,6.97,12.96v1.68H133.37z"
          />
          <path
            className="st0"
            d="M79.04,91.01c5.09,0,9.22-4.13,9.22-9.22c0-5.09-4.13-9.21-9.22-9.21s-9.22,4.13-9.22,9.21
		C69.83,86.89,73.95,91.01,79.04,91.01z"
          />
          <path
            className="st0"
            d="M120.96,91.01c5.09,0,9.22-4.13,9.22-9.22c0-5.09-4.13-9.21-9.22-9.21s-9.22,4.13-9.22,9.21
		C111.74,86.89,115.87,91.01,120.96,91.01z"
          />
          <path
            className="st0"
            d="M100,98.44c4.85,0,8.79-3.94,8.79-8.79c0-4.84-3.94-8.79-8.79-8.79s-8.79,3.94-8.79,8.79
		C91.21,94.5,95.15,98.44,100,98.44z"
          />
          <path
            className="st0"
            d="M112.41,114.88c0-6.78-5.51-12.29-12.29-12.29c-7.03,0-12.54,5.51-12.54,12.29v12.54h24.83V114.88z"
          />
        </g>
      </svg>
    </>
  );
};

export default PeopleSVG;
