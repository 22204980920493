import React from "react";

const RiaRelationships = (props) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        xmlSpace="preserve"
      >
        <g className={props.circleChange}>
          <path
            className="st0"
            d="M100,32.42c37.26,0,67.58,30.32,67.58,67.58s-30.32,67.58-67.58,67.58S32.42,137.26,32.42,100
		S62.74,32.42,100,32.42 M100,26.42c-40.64,0-73.58,32.94-73.58,73.58s32.94,73.58,73.58,73.58s73.58-32.94,73.58-73.58
		S140.64,26.42,100,26.42L100,26.42z"
          />
        </g>
        <g className={props.imageChange}>
          <path
            className="st0"
            d="M124.81,104.64c1.58,1.2,2.82,2.49,2.84,4.52c0.03,2.5-1.48,4.24-3.96,4.46c-0.6,0.05-0.87,0.13-1,0.83
		c-0.58,2.96-2.29,4.13-5.34,3.75c-0.53-0.07-0.63,0.08-0.76,0.56c-0.87,3.26-2.03,4.1-5.42,3.89c-0.64-0.04-0.71,0.17-0.82,0.67
		c-0.69,2.99-3.05,4.13-5.86,2.85c-1.05-0.47-2.06-1.03-3.13-1.45c-0.81-0.32-0.87-0.68-0.58-1.47c0.4-1.09,0.88-1.2,1.83-0.66
		c0.88,0.5,1.86,0.83,2.77,1.27c0.94,0.45,1.82,0.55,2.44-0.44c0.5-0.79,0.09-2.1-0.85-2.65c-0.99-0.58-2.03-1.07-3.06-1.59
		c-0.79-0.4-1.38-0.94-0.94-1.88c0.46-0.97,1.24-0.78,2.03-0.37c1.85,0.96,3.7,1.91,5.55,2.84c1.55,0.78,2.68,0.63,3.31-0.39
		c0.67-1.11,0.31-2.52-1.09-3.41c-1.61-1.02-3.32-1.87-4.98-2.79c-0.39-0.21-0.78-0.42-1.17-0.64c-0.72-0.39-1.08-0.98-0.7-1.75
		c0.44-0.88,1.16-0.81,1.92-0.4c3,1.62,6,3.25,9,4.86c1.55,0.84,2.62,0.7,3.23-0.42c0.62-1.15,0.15-2.36-1.3-3.16
		c-3.06-1.7-6.14-3.36-9.2-5.05c-1.07-0.59-1.34-1.42-0.73-2.12c0.6-0.69,1.24-0.43,1.91-0.06c3.84,2.11,7.7,4.2,11.54,6.31
		c0.82,0.45,1.61,0.5,2.35-0.09c0.67-0.54,0.74-1.31,0.51-2.07c-0.18-0.6-0.62-1.06-1.13-1.44c-7.57-5.65-15.48-10.8-23.45-15.87
		c-1.44-0.92-3.3-0.43-4.93-0.82c-1.93-0.47-3.44-0.03-4.76,1.58c-1.07,1.3-2.54,2.21-4.26,2.55c-2.03,0.4-3.77-0.15-5.17-1.69
		c-0.63-0.7-0.71-1.31,0.12-2.01c1.77-1.49,3.23-3.29,4.71-5.06c1.13-1.35,2.56-1.76,4.19-1.96c3.87-0.49,7.73-1.03,11.59-1.58
		c0.78-0.11,1.47,0.04,2.19,0.32c4.04,1.59,8.1,3.1,12.25,4.37c2.21,0.67,4.11-0.09,5.94-1.13c0.77-0.44,1.19-0.39,1.76,0.28
		c3.7,4.28,5.69,9.23,6.03,14.86c0.05,0.79-0.22,1.2-0.94,1.51C127.84,103.15,126.43,103.87,124.81,104.64z M78.59,84.9
		c-1.05-0.34-1.74-0.11-2.45,0.68c-4.14,4.64-6.39,10.02-6.6,16.25c-0.04,1.13,0.29,1.84,1.38,2.25c2.82,1.06,5.19,2.82,7.31,4.94
		c0.32,0.32,0.6,0.75,1.01,0.79c1.2-1.58,0.63-2.68-0.7-3.83c-1.66-1.43-3.31-2.82-5.39-3.56c-1.04-0.37-1.26-0.98-1.14-2.01
		c0.52-4.37,2.08-8.29,4.73-11.81c0.74-0.98,1.39-1.29,2.63-0.9c2.43,0.77,2.52,0.65,3.92-1.63C81.67,85.69,80.1,85.39,78.59,84.9z
		 M95.78,111.54c-1.05-0.32-2.49,0.22-2.98,1.23c-1.11,2.3-2.2,4.63-3.18,6.99c-0.41,0.99-0.19,2.04,0.75,2.74
		c0.94,0.69,1.9,0.54,2.84-0.08c0.49-0.33,0.84-0.77,1.1-1.3c0.91-1.86,1.83-3.72,2.72-5.6c0.22-0.47,0.31-1,0.42-1.37
		C97.34,112.87,96.99,111.9,95.78,111.54z M87,110.76c-0.85,1.65-1.6,3.35-2.35,5.05c-0.15,0.35-0.17,0.78-0.16,1.17
		c0.02,1.06,0.46,1.92,1.53,2.24c1.09,0.33,2.13,0,2.66-0.99c1.04-1.95,2-3.95,2.82-5.99c0.58-1.44-0.61-2.95-2.12-2.94
		C88.3,109.32,87.47,109.85,87,110.76z M99.72,117.54c-1-0.41-1.91-0.08-2.5,0.68c-1.05,1.36-1.86,2.89-2.38,4.53
		c-0.28,0.9-0.04,1.77,0.81,2.32c0.81,0.51,1.69,0.38,2.33-0.23c1.54-1.46,2.23-3.42,2.92-5.15
		C100.79,118.64,100.58,117.9,99.72,117.54z M84.03,107.66c-0.93-0.45-1.81-0.21-2.57,0.47c-1.08,0.96-1.79,4.26-1.23,5.59
		c0.36,0.85,0.99,1.38,1.9,1.44c0.78,0.05,1.6-0.2,1.93-0.93c0.59-1.32,1.15-2.67,1.28-3.85C85.34,109.04,85.01,108.14,84.03,107.66
		z M123.99,82.49c0,0,9.17,5.63,8.81,21.12c0,0,3.67-0.43,8.29-3.73c0,0-2.13-18.2-8.51-20.54
		C132.58,79.35,127.86,78.76,123.99,82.49z M67.06,104.48c0,0-6.82-1.07-8.14-2.62c0,0,1.17-16.56,10.62-21.58c0,0,4.69-0.63,6.87,2
		C76.41,82.28,66.18,89.72,67.06,104.48z M64.35,99.34c0-0.81-0.66-1.48-1.48-1.48c-0.81,0-1.48,0.66-1.48,1.48
		c0,0.81,0.66,1.48,1.48,1.48C63.69,100.82,64.35,100.16,64.35,99.34z"
          />
        </g>
      </svg>
    </>
  );
};

export default RiaRelationships;
