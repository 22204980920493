import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <p className="align_right">LAST UPDATED: 2/7/2020</p>
      <h4 className="align_center xtra_margin_bottom">
        CALIFORNIA CONSUMER PRIVACY ACT NOTICE TO EMPLOYEES
      </h4>
      <p>
        StratCap, LLC (collectively, “<strong>StratCap</strong>,”
        “<strong>we</strong>” or “<strong>our</strong>”) is providing the
        following details regarding the categories of personal information that
        we have collected about California residents who are StratCap
        employees, independent contractors, job applicants, owners, directors or
        officers (and their emergency contacts and recipients of employment
        benefits) (“<strong>Personal Information</strong>”).{" "}
      </p>

      <p>
        We supply this information consistent with the California Consumer
        Privacy Act of 2018 (“<strong>CCPA</strong>”).
      </p>

      <p>
        Please provide this notice to your emergency contacts and recipients of
        your employment benefits by, as applicable, forwarding them the email
        with this notice attached or providing them with a copy of this notice.
      </p>

      <p className="">
        We collect the following categories of Personal Information:
      </p>
      <p className="ul_margin">
        A. Identifiers, such as name, contact information, online identifiers
        and Social Security numbers and other government-issued ID numbers;
      </p>
      <p className="ul_margin">
        B. Personal information, as defined in the California customer records
        law, such as name, contact information, education information,
        employment history, financial information and medical and medical
        insurance information;
      </p>
      <p className="ul_margin">
        C. Characteristics of protected classifications under California or
        federal law, such as sex, age, race, religion, national origin,
        disability, medical conditions and information, citizenship, immigration
        status, request for leave and marital status;
      </p>
      <p className="ul_margin">
        D. Biometric information, such as fingerprints;
      </p>
      <p className="ul_margin">
        E. Internet or network activity information, such as browsing history
        and interactions with our and other websites and systems;{" "}
      </p>
      <p className="ul_margin">F. Geolocation data, such as IP location;</p>
      <p className="ul_margin">
        G. Audio, electronic, visual, and similar information, such as
        photographs or audio or video recordings created in connection with our
        business activities;
      </p>
      <p className="ul_margin">
        H. Professional or employment-related information, such as work history,
        prior employer, information relating to references, details of
        qualifications, skills and experience, human resources data, and data
        necessary for benefits and related administration services;
      </p>
      <p className="ul_margin">
        I. Education information subject to the federal Family Educational
        Rights and Privacy Act; and
      </p>
      <p className="ul_margin">
        J. Inferences drawn from any of the Personal Information listed above to
        create a profile or summary about, for example, an individual’s
        preferences and characteristics.
      </p>
      <p>
        We use this Personal Information for the purposes of operating,
        managing, and maintaining our business, managing our workforce, and
        accomplishing our business purposes and objectives, including, for
        example, using Personal Information to:{" "}
      </p>
      <p className="ul_margin">
        • Manage workforce activities and personnel generally, including for
        recruitment, background screening, performance management, career
        development, payments administration, employee training, leaves and
        promotions;
      </p>
      <p className="ul_margin">
        • Administer hiring, promotion, and discipline;
      </p>
      <p className="ul_margin">
        • Provide an efficient means for personnel to obtain the contact
        information of their colleagues so they may contact them;
      </p>
      <p className="ul_margin">
        • Manage payroll, wages, tax forms and filing, expense reimbursements,
        and other awards such as stock options, stock grants and bonuses, and
        provide healthcare, pensions, savings plans and other benefits;{" "}
      </p>
      <p className="ul_margin">
        • Calculate insurance and other employee benefits;
      </p>
      <p className="ul_margin">
        • Monitor personal investments to comply with SEC insider trading
        regulations;
      </p>
      <p className="ul_margin">
        • Notify family members in case of an emergency;
      </p>
      <p className="ul_margin">
        • Maintain and secure our facilities, equipment, systems, and
        infrastructure;{" "}
      </p>
      <p className="ul_margin">
        • Undertake quality and safety assurance measures, protect the health
        and safety of our workforce and others, and conduct risk and security
        control and monitoring;{" "}
      </p>
      <p className="ul_margin">
        • Determine and monitor compliance with worldwide training and other
        policies;
      </p>
      <p className="ul_margin">
        • Conduct research, analytics, and data analysis to assist in planning
        succession and to ensure business continuity, as well as to design
        employee retention programs and diversity initiatives;{" "}
      </p>
      <p className="ul_margin">
        • Perform identity verification, accounting, audit, and other internal
        functions, such as internal investigations;{" "}
      </p>
      <p className="ul_margin">
        • Monitor use of IT infrastructure, internet access, and electronic
        communication for unauthorized, unlawful, or inappropriate use;
      </p>
      <p className="ul_margin">
        • Record phone calls for training, quality assurance, and legal
        compliance purposes;
      </p>
      <p className="ul_margin">
        • Operate and manage IT and communications systems and facilities,
        allocate company assets and human resources, and undertake strategic
        planning and project management;
      </p>
      <p className="ul_margin">
        • Obtain legal advice and establish, exercise or defend legal rights,
        and act on collection and discovery requests in the context of
        litigation, government investigations or regulatory audits or inquiries;
        and
      </p>
      <p className="ul_margin">
        • Comply with law, legal process, investigations, internal policies and
        other requirements such as income tax deductions, monitoring,
        record-keeping and reporting obligations.
      </p>
      <p className="no_margin_p">
        <strong>Changes to this CCPA Notice</strong>
      </p>
      <p>
        We may change or update this CCPA Notice from time to time. When we do,
        we will post the revised CCPA Notice on this page with a new “Last
        Updated” date.
      </p>
    </>
  );
};

export default PrivacyPolicy;
