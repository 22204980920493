import React from "react";
import ManagementProfile from "../ManagementProfile/ManagementProfile";
import image from "../../assets/team/JimKearny.jpg";

const JimKearnyBio = () => {
  const name = "JAMES R. KEARNY, MBA";
  const title = "Chief Compliance Officer, StratCap";
  const bio = `Jim has been with StratCap Securities since October of 2010 and is its Chief Compliance Officer. Jim has more than 20 years of progressively responsible experience in SEC/FINRA regulatory compliance, audits, product distribution and due diligence, and asset manager research. He has worked in multiple senior operating roles at several dual-registered BDs/Investment Advisors in New York City and on the West Coast such as Lehman Brothers, Donaldson Lufkin, & Jenrette, ING, Canterbury Consulting, and Dunham and Associates Investment Counsel.`;
  const bio2 = `Mr. Kearny has a B.S. Marketing and an M.B.A. in Management from St. John’s University in Queens, New York. He has a Series 7, 14, 24, 53, 55, 57, 63, and 99 registrations. He currently lives in Southern California with his wife and children.`;

  return (
    <ManagementProfile
      name={name}
      title={title}
      image={image}
      bio={bio}
      bio2={bio2}
    />
  );
};

export default JimKearnyBio;
