import React from "react";
import "./JobPages.css";
import { Row, Col, Container, Image } from "react-bootstrap";
import NavbarPages from "../../Containers/NavbarPages";
import scLogo from "../../assets/images/SC.jpg";
import careerHero from "../../assets/images/career_hero.jpg";

const Specialist = () => {
  return (
    <>
      <NavbarPages />
      <div className="career_hero_container">
          <div className="career_adjustment_layer">
            <Image src={careerHero} fluid />
          </div>
          <div className="hero_text">Come Work With Us</div>
        </div>
      <Container>
        <Row className="jobs_container">
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Image src={scLogo} className="specialist_logo" />
              </Col>
              <Col lg={6} className="specialist_resume_button_container">
                <a
                  className="btn-u"
                  href="mailto:career@scdistributors.com?Subject=Client%20Service%20Specialist"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply with Resume
                </a>
              </Col>
            </Row>
            <h2 className="jobs_bold">Client Service Specialist</h2>
            <p>at StratCap</p>
            <p>Costa Mesa, CA - Sales</p>
            <p>
              The Client Service Specialist works within the corporate
              environment and is partnered with the internal sales associates.
              This individual is responsible for taking inbound information
              calls from financial advisors regarding product inquiries. The
              Client Service Specialist helps to create new relationships and
              support existing relationships with home office staff and
              financial advisors while supporting Strategic Capital product
              sales efforts throughout the United States. This is accomplished
              through an information and consultative marketing approach
              primarily by providing financial and product information via the
              telephone, so representatives have the tools to effectively offer
              our platform of alternative investments to their clients. This
              position requires an individual that has exceptional customer
              service skills, excellent phone skills, strong work ethic, and a
              competitive drive to succeed. The Client Service Specialist
              position generally is viewed as a stepping stone to becoming an
              Internal Sales Associate, which will require securities
              registration(s).
            </p>
            <p>
              This is an entry level position with upward mobility towards a
              licensed broker position. This is a great avenue to access a
              career in the financial services industry, and develop skills
              necessary to be successful in an exciting and fast-paced work
              environment.
            </p>
            <p>This Position Reports To: VP/ Sales Desk Manager.</p>
            <h4 className="jobs_bold">Essential Job Duties & Responsibilities</h4>
            <ul>
              <li>Exceptional customer service skills</li>
              <li>An interest in investment marketing and sales</li>
              <li>
                Possess an interest in the capital markets and alternative
                investments
              </li>
              <li>
                Must have the ability to work in a challenging and collaborative
                environment
              </li>
              <li>Possess a strong desire to win</li>
              <li>Possess excellent oral and written communication skills)</li>
              <li>Possess excellent interpersonal skills</li>
              <li>Proven ability to pay attention to detail and multi-task</li>
              <li>Must have strong relationship building skills</li>
              <li>
                Must be a detail-oriented, well-organized self-starter with high
                energy and creativity
              </li>
            </ul>
            <h4 className="jobs_bold">Education & Experience Required</h4>
            <ul>
              <li>Bachelor's degree (Preferred)</li>
              <li>Certifications, Licenses, Registrations Required</li>
              <li>
                Required to obtain a Series 7 license within 12 months. (company
                sponsored)
              </li>
            </ul>
            <h4 className="jobs_bold">About StratCap</h4>
            <p>
              Strategic Capital’s goal is to be the premier distribution
              platform for select alternative investment products. Our mission
              is to connect investors and financial advisors with a focused
              group of leading alternative investment managers. Through a
              demonstrated financial services wholesale distribution model, we
              deliver these products to financial advisors (and their clients)
              throughout the United States.
            </p>
            <p>
              Strategic Capital offers an outstanding benefits package including
              competitive pay, bonuses, comprehensive health insurance benefits,
              a 401(k) retirement plan.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Specialist;
