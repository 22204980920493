import React from "react";
import "./JobPages.css";
import scLogo from "../../assets/images/SC.jpg";
import { Row, Col, Container, Image } from "react-bootstrap";
import NavbarPages from "../../Containers/NavbarPages";
import careerHero from "../../assets/images/career_hero.jpg";

const SalesAssociate = () => {
  return (
    <>
      <NavbarPages />
      <div className="career_hero_container">
        <div className="career_adjustment_layer">
          <Image src={careerHero} fluid />
        </div>
        <div className="hero_text">Come Work With Us</div>
      </div>
      <Container>
        <Row className="jobs_container">
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Image src={scLogo} className="specialist_logo" />
              </Col>
              <Col
                lg={6}
                sm={12}
                className="specialist_resume_button_container"
              >
                <a
                  className="btn-u"
                  href="mailto:career@scdistributors.com?Subject=Sales%20Associate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply with Resume
                </a>
              </Col>
            </Row>
            <h2 className="jobs_bold jobs_jobtitle_margin">
              Internal Sales Associate
            </h2>
            <p>at StratCap</p>
            <p>Costa Mesa, CA - Sales</p>
            <p>Job Type: Full-time</p>
            <p>Salary: $50,000.00 to $80,000.00 /year</p>
            <p>
              The Internal Sales Associate works within the corporate
              environment and is partnered with a Regional Vice President (RVP)
              in a geographical sales territory. This individual is responsible
              for partnering with an RVP, creating new and managing existing
              relationships with financial advisors to increase market share of
              Strategic Capital products throughout the given territory. This is
              accomplished through a consultative sales approach providing
              financial insight and product information via the telephone, so
              representatives can effectively offer our platform of alternative
              investments to their clients. This position requires an individual
              that has a sales focus with excellent phone skills, strong work
              ethic and a competitive drive to succeed.
            </p>

            <p>This Position Reports To: National Sales Manager</p>
            <h4 className="jobs_bold">
              Essential Job Duties & Responsibilities
            </h4>
            <ul>
              <li>
                Make proactive sales calls on a daily basis with existing
                representatives to strengthen relationships and find selling
                opportunities. This includes sales campaigns, new business,
                follow-up calls, and others as directed.
              </li>
              <li>Schedule quality appointments each week for the RVP.</li>
              <li>
                Proactively research and manage territory goals and initiatives.
              </li>
              <li>
                Work closely with the RVP to develop and execute an appropriate
                business plan to maximize territory sales.
              </li>
              <li>Identify and actively convert prospects into producers.</li>
              <li>
                Utilize Strategic Capital's proprietary CRM system to create and
                maintain up to date information on the territory.
              </li>
            </ul>
            <h4 className="jobs_bold">
              Job Requirements (Knowledge, Skills, and Abilities)
            </h4>
            <ul>
              <li>Prior investment industry experience</li>
              <li>
                Must have the ability to work in a challenging and collaborative
                environment
              </li>
              <li>Possess a strong desire to win</li>
              <li>Possess excellent oral and written communication skills</li>
              <li>
                Possess excellent interpersonal and customer service skills
              </li>
              <li>Proven ability to pay attention to detail and multi-task</li>
              <li>Must have strong relationship building skills</li>
              <li>
                Must be a detail-oriented, well-organized self-starter with high
                energy and creativity
              </li>
              <li>Securities licensed preferred (Series 7 & 63, etc)</li>
              <li>
                Must have the ability to work in a challenging and collaborative
                environment
              </li>
            </ul>
            <h4 className="jobs_bold">
              Strategic Capital Management Team Experience
            </h4>
            <p>
              Over 75 years of combined experience in wholesale distribution
              management. Helped raise over $20 billion in equity capital over
              the last 15 years.. Successful in distributing Business
              Development Companies, Non-Traded REITs, Reg D private placement
              offerings and LLC’s .{" "}
            </p>
            <h4 className="jobs_bold">About StratCap</h4>
            <p>
              Strategic Capital’s goal is to be the premier distribution
              platform for select alternative investment products. Our mission
              is to connect investors and financial advisors with a focused
              group of leading alternative investment managers. Through a
              demonstrated financial services wholesale distribution model, we
              deliver these products to financial advisors (and their clients)
              throughout the United States.
            </p>
            <p>
              Our leadership's extensive experience in wholesale distribution,
              combined with our strategy to engage a prominent real estate
              investment banking organization, helped enable us to identify and
              evaluate the capabilities, backgrounds, financial sustainability
              and track records of a wide variety of potential investment
              managers. Finally, our leadership's extensive distribution
              experience and our proprietary SCORE (Strategic Capital Online
              Reporting Engine) technology platform helps us to effectively and
              efficiently track and manage each step of the product delivery
              process for our financial advisor and broker/dealer partners.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SalesAssociate;
