import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../Management/Management.css"

const EmpCard = (props) => {
  return (
    <>
      <Card>
        <Card.Img variant="top" src={props.image} fluid/>
        <Card.Body>
          <Card.Title className="management_title_nomargin">
            {props.name.toUpperCase()}
          </Card.Title>
          <p className="management_p_nomargin">{props.title.toUpperCase()}</p>
          <hr className="hr_width" />
          <Card.Text>
            {props.text}
            <br />
            <Link to={props.link}> Read More</Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default EmpCard;
