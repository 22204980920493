import React from "react";
import ManagementProfile from "../ManagementProfile/ManagementProfile";
import image from "../../assets/team/ErikR.jpg";

const ErikBio = () => {
  const name = "ERIK ROSTVOLD";
  const title = "SVP - Product Management, StratCap";
  const bio = `Erik Rostvold is the Senior Vice President of Product Management with StratCap.
  With 15 years of experience, Erik has been involved in over $15B in alternative investment offerings. He currently plays integral roles in product development, competitive benchmarking, as well as the due diligence process with broker-dealers and third-party due diligence firms. Erik is also instrumental in the training of the sales team as well as interfacing with financial advisors regarding the intricacies of each offering. Before StratCap, Erik was Director of Product Management at Cole Real Estate Investments. Before Cole, Erik has held product management and sales positions with KBS Capital Markets Group, MetLife, and Pacific Life.`;
  const bio2 = `Erik lives in Lake Forest, CA, with his wife Patty and daughters, Raissa and Thais`;

  return (
    <ManagementProfile
      name={name}
      title={title}
      image={image}
      bio={bio}
      bio2={bio2}
    />
  );
};

export default ErikBio;
