import React from "react";
import { Row, Col, Image } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CountUp from "react-countup";
import "./Distribution.css";
import checkMark from "../../assets/images/checkmark.png";
import PeopleSvg from "../SVGs/People";
import MoneySvg from "../SVGs/Money";
import RiaRel from "../SVGs/RiaRelationships";
import BdRel from "../SVGs/BdRelationships";
import handleViewport from "react-in-viewport";

import dist1Img from "../../assets/images/dist1.png";
import dist2Img from "../../assets/images/dist2.png";
import dist3Img from "../../assets/images/dist3.png";
import dist4Img from "../../assets/images/dist4.png";

const Money = (props) => {
  const { inViewport, innerRef } = props;
  const circleAnimation = inViewport
    ? "svg_circle viewport-block"
    : "viewport-block";
  const imageAnimation = inViewport
    ? "svg_image viewport-block"
    : "viewport-block";

  return (
    <div ref={innerRef}>
      <MoneySvg circleChange={circleAnimation} imageChange={imageAnimation} />
    </div>
  );
};

const People = (props) => {
  const { inViewport, innerRef } = props;
  const circleAnimation = inViewport
    ? "svg_circle viewport-block"
    : "viewport-block";
  const imageAnimation = inViewport
    ? "svg_image viewport-block"
    : "viewport-block";

  return (
    <div ref={innerRef}>
      <PeopleSvg circleChange={circleAnimation} imageChange={imageAnimation} />
    </div>
  );
};

const Riarelation = (props) => {
  const { inViewport, innerRef } = props;
  const circleAnimation = inViewport
    ? "svg_circle viewport-block"
    : "viewport-block";
  const imageAnimation = inViewport
    ? "svg_image viewport-block"
    : "viewport-block";

  return (
    <div ref={innerRef}>
      <RiaRel circleChange={circleAnimation} imageChange={imageAnimation} />
    </div>
  );
};

const Bdrelation = (props) => {
  const { inViewport, innerRef } = props;
  const circleAnimation = inViewport
    ? "svg_circle viewport-block"
    : "viewport-block";
  const imageAnimation = inViewport
    ? "svg_image viewport-block"
    : "viewport-block";

  return (
    <div ref={innerRef}>
      <BdRel circleChange={circleAnimation} imageChange={imageAnimation} />
    </div>
  );
};

const ViewportMoney = handleViewport(Money /** options: {}, config: {} **/);
const ViewportPeople = handleViewport(People /** options: {}, config: {} **/);
const ViewportRiarelation = handleViewport(
  Riarelation /** options: {}, config: {} **/
);
const ViewportBdrelation = handleViewport(
  Bdrelation /** options: {}, config: {} **/
);

const Distribution = (props) => {
  return (
    <div className="dist_container">
      <div className="container">
        <Row className="align_center">
          <h2 className="dist_header">A Robust Distribution Organization</h2>
        </Row>
        <Row className="align_center">
          <p className="dist_info_para">
            Since its founding in 2009, StratCap has helped develop a number of
            alternative investments targeting specialized strategies and
            distinct offering structures.
          </p>
        </Row>
        <Row className="dist_images_row">
          <Col lg={3} sm={12} align={"center"}>
            {/* <ViewportMoney /> */}
            {/* <MoneySvg /> */}
            <img src={dist1Img} alt="" className="dist-img" />
            <p className="dist_large_text">$5 BILLION</p>
            <p className="dist_para_color">
              APPROXIMATE INVESTOR CAPITAL RAISED
            </p>
          </Col>
          <Col lg={3} sm={12} align={"center"}>
            {/* <ViewportPeople /> */}
            {/* <PeopleSvg /> */}
            <img src={dist2Img} alt="" className="dist-img" />
            <p className="dist_large_text">100,000</p>
            {/* <CountUp delay={3} end={100000} /> */}
            <p className="dist_para_color">APPROXIMATE # OF INVESTORS</p>
          </Col>
          <Col lg={3} sm={12} align={"center"}>
            {/* <ViewportRiarelation /> */}
            {/* <RiaRel /> */}
            <img src={dist3Img} alt="" className="dist-img" />
            <p className="dist_large_text">3000+</p>
            <p className="dist_para_color">RIA RELATIONSHIPS</p>
          </Col>
          <Col lg={3} sm={12} align={"center"}>
            {/* <ViewportBdrelation /> */}
            {/* <BdRel /> */}
            <img src={dist4Img} alt="" className="dist-img" />
            <p className="dist_large_text">400+</p>
            <p className="dist_para_color">BD RELATIONSHIPS</p>
          </Col>
        </Row>
        <Row
          align={"center"}
          // className="justify-content-md-center dist_check_row"
        >
          <Col lg={3} sm={6} className="dist_checks">
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Wholesale Distribution</span>
            </p>
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Due Diligence</span>
            </p>
          </Col>
          <Col lg={3} sm={6} className="dist_checks">
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Product Development</span>
            </p>
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Operations</span>
            </p>
          </Col>
          <Col lg={3} sm={6} className="dist_checks">
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Compliance & Legal</span>
            </p>
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Marketing & Research</span>
            </p>
          </Col>
          <Col lg={3} sm={6} className="dist_checks">
            <p>
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Sales, Service & Support</span>
            </p>
            <p>
              {/* <FontAwesomeIcon icon="check" /> */}
              <Image src={checkMark} /> &nbsp;{" "}
              <span className="dist_para_color">Technology & Reporting</span>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Distribution;
