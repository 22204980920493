import { BrowserRouter as Router, Route, HashRouter } from "react-router-dom";
import React, { useReducer, createContext, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./App.css";
import "lightbox-react/style.css";

// Components
import Home from "./Containers/Home";
import Management from "./Components/Management/Management";
import Contact from "./Components/ContactUs/ContactUs";
import Footer from "./Components/Footer/Footer";
// import Careers from "./Components/Careers/Careers";
import AssetPage from "./Components/AssetSpotlight/AssetPage";
import Specialist from "./Components/JobPages/Specialist";
import SalesAssociate from "./Components/JobPages/SalesAssociate";
import SeniorAccountant from "./Components/JobPages/SeniorAccountant";
import EAA from "./Components/JobPages/EAA";
// import PreFooter from "./Components/PreFooter/PreFooter";
import LoginPage from "./Components/login/LoginPage";

// Bios
import PatMillerBio from "./Components/Bios/PatMillerBio";
import JimCondonBio from "./Components/Bios/JimCondonBio";
import RobBruceBio from "./Components/Bios/RobBruceBio";
import JimKearnyBio from "./Components/Bios/JimKearnyBio";
import ErikBio from "./Components/Bios/ErikBio";
// import KaraBio from "./Components/Bios/KaraBio";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHome,
  faPhone,
  faGlobe,
  faChevronDown,
  faMoneyBillWave,
  faMugHot,
  faUserInjured,
  faCarrot,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheck,
  faHome,
  faPhone,
  faGlobe,
  faChevronDown,
  faMoneyBillWave,
  faMugHot,
  faUserInjured,
  faCarrot,
  faChevronRight
);

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

// Auth Context will pass the auth state from this component to any other component that needs it.
export const AuthContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App() {
  const password = process.env.REACT_APP_PASS;
  const envType = process.env.REACT_APP_TYPE;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = sessionStorage.getItem("userpass");

    if (token && token === password) {
      dispatch({
        type: "LOGIN",
        payload: {
          token,
        },
      });
    }
  }, []);

  const renderLogin = () => {
    if (envType === "test") {
      return (
        <HashRouter>
          <AuthContext.Provider value={{ state, dispatch }}>
            <Route component={ScrollToTop} />
            <Route exact path="/">
              {!state.isAuthenticated ? <LoginPage /> : <Home />}
            </Route>
            <Route path="/managementteam">
              {!state.isAuthenticated ? <LoginPage /> : <Management />}
            </Route>
            <Route path="/contactus">
              {!state.isAuthenticated ? <LoginPage /> : <Contact />}
            </Route>
            <Route path="/PatrickMiller">
              {!state.isAuthenticated ? <LoginPage /> : <PatMillerBio />}
            </Route>
            <Route path="/JimCondon">
              {!state.isAuthenticated ? <LoginPage /> : <JimCondonBio />}
            </Route>
            <Route path="/RobertBruce">
              {!state.isAuthenticated ? <LoginPage /> : <RobBruceBio />}
            </Route>
            <Route path="/JimKearny">
              {!state.isAuthenticated ? <LoginPage /> : <JimKearnyBio />}
            </Route>
            <Route path="/ErikRostvold">
              {!state.isAuthenticated ? <LoginPage /> : <ErikBio />}
            </Route>
            <Route path="/Clientservicespecialist">
              {!state.isAuthenticated ? <LoginPage /> : <Specialist />}
            </Route>
            <Route path="/senioraccountant">
              {!state.isAuthenticated ? <LoginPage /> : <SeniorAccountant />}
            </Route>
            <Route path="/executiveadministrativeassistant">
              {!state.isAuthenticated ? <LoginPage /> : <EAA />}
            </Route>
            <Route path="/internalsalesassociate">
              {!state.isAuthenticated ? <LoginPage /> : <SalesAssociate />}
            </Route>
            <Route path="/assetspotlights">
              {!state.isAuthenticated ? <LoginPage /> : <AssetPage />}
            </Route>
            {!state.isAuthenticated ? null : <Footer />}
          </AuthContext.Provider>
        </HashRouter>
      );
    } else {
      return (
        <HashRouter>
          <Route component={ScrollToTop} />
          <Route exact path="/" component={Home} />
          <Route path="/managementteam" component={Management} />
          <Route path="/contactus" component={Contact} />
          <Route path="/PatrickMiller" component={PatMillerBio} />
          <Route path="/JimCondon" component={JimCondonBio} />
          <Route path="/RobertBruce" component={RobBruceBio} />
          <Route path="/JimKearny" component={JimKearnyBio} />
          <Route path="/ErikRostvold" component={ErikBio} />
          {/* <Route path="/Careers" component={Careers} /> */}
          <Route path="/Clientservicespecialist" component={Specialist} />
          <Route path="/senioraccountant" component={SeniorAccountant} />
          <Route path="/executiveadministrativeassistant" component={EAA} />
          <Route path="/internalsalesassociate" component={SalesAssociate} />
          <Route path="/assetspotlights" component={AssetPage} />
          {/* <PreFooter /> */}
          <Footer />
        </HashRouter>
      );
    }
  };

  return <>{renderLogin()}</>;
}

export default App;
