import React, { Component } from "react";
import { Row, Col, Image, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import Terms from "../TermsOfUse/Terms";
import bcImage from "../../assets/images/broker_check.png";

class PrivacyModal extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="privacy-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrivacyPolicy />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class TermsModal extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="privacy-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Terms Of Use
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Terms />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class Footer extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      privacyModalShow: false,
      termsModalShow: false,
    };
  }

  render() {
    let modalClose = () => this.setState({ privacyModalShow: false });
    let termsModalClose = () => this.setState({ termsModalShow: false });
    const footerLogo = `assets/logoWhite.png`;
    const skuLink = process.env.REACT_APP_SKU;

    return (
      <>
        <div className="footer_row">
          <div className="container">
            <Row>
              <Col lg={3} sm={6} className="footer_logo_col">
                <Link to="/">
                  <Image src={footerLogo} fluid />
                </Link>
              </Col>
              <Col lg={3} sm={6} className="footer_sc_row">
                <div className="footer_sep_div">
                  <h5 className="footer_headers">StratCap Securities</h5>

                  <p className="footer_paras">StratCap Securities</p>
                  <p className="footer_paras">
                    3101 West Coast Highway, Suite 140
                  </p>
                  <p className="footer_paras">Newport Beach, CA 92663</p>
                  <p className="footer_paras">
                    <Link to="/contactus" className="footer_link">
                      Map&nbsp; &#124; &nbsp;Directions
                    </Link>
                  </p>
                </div>
              </Col>
              <Col lg={3} sm={6} className="footer_quick_links">
                <h5 className="footer_headers">Quick Links</h5>
                <p className="footer_paras">
                  <Link to="/managementteam" className="footer_link">
                    LEADERSHIP
                  </Link>
                </p>
                <p className="footer_paras">
                  <Link to="/contactus" className="footer_link">
                    CONTACT US
                  </Link>
                </p>
                {/* <p className="footer_paras">OFFERINGS</p> */}
              </Col>
              <Col lg={3} sm={6}>
                <h5 className="footer_headers">Contact Us</h5>

                <p className="footer_paras">
                  Main Office: <a href="tel:949-681-9125">949-681-9125</a>
                </p>
                <p className="footer_paras">
                  Financial Advisors:{" "}
                  <a href="tel:877-907-1148">(877) 907-1148</a>
                </p>
              </Col>
            </Row>
            <hr align={"center"} className="footer_lower_hr" />
            <div className="container">
              <p>
                Alternative investments typically are speculative, carry
                substantial risk of loss, and are illiquid.
              </p>
            </div>
            <div className="prefooter_main_container">
              <div className="container offer_container">
                <Row>
                  <p className="offer_para">NOT AN OFFER TO SELL SECURITIES</p>
                  <p className="offer_text">
                    The material in this Web site does not constitute an offer
                    to sell, nor a solicitation of an offer to buy the
                    securities described herein. Such an offering is made only
                    by means of a prospectus. The prospectus must be read in
                    order to understand fully all the implications and risks of
                    any offering of securities to which it relates. A copy of
                    the prospectus must be made available to you in connection
                    with an offering. Neither the Securities and Exchange
                    Commission, the attorney General of the state of New York
                    nor any other state securities regulator has approved or
                    disapproved of these securities or determined the prospectus
                    is truthful or complete. Any representation to the contrary
                    is a criminal offense. Investing involves a high degree of
                    risk and there is no assurance that investment objectives of
                    these programs will be met. Refer to the prospectus for
                    suitability standards in your state. Please contact your
                    Financial Advisor for more Information.{" "}
                    <strong>
                      Securities offered through StratCap Securities, LLC member{" "}
                      <a
                        href="http://www.finra.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FINRA
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.sipc.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SIPC
                      </a>
                    </strong>{" "}
                    .
                    <span className="footer_brokerCheck_span">
                      <a
                        href="https://brokercheck.finra.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src={bcImage} className="footer_broker_image" />
                      </a>
                    </span>
                  </p>
                </Row>
              </div>
            </div>
            <Row>
              <Col lg={8} sm={12} className="footer_bottom_footer_no_padding">
                <span className="footer_span_start">
                  2019 &#169; All Rights Reserved.
                </span>
                <span
                  onClick={() => this.setState({ privacyModalShow: true })}
                  className="footer_privacy_span"
                >
                  Privacy Policy
                </span>
                <span
                  onClick={() => this.setState({ termsModalShow: true })}
                  className="footer_term_span"
                  style={{ borderRight: "1px solid rgb(156, 156, 156)" }}
                >
                  Terms of Use
                </span>
                <span className="footer_term_span">
                  <a
                    href={skuLink + "SC0030"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    Business Continuity Plan
                  </a>
                </span>
                {/* <span className="footer_brokerCheck_span">
                  <a
                    href="https://brokercheck.finra.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src={bcImage} className="footer_broker_image" />
                  </a>
                </span> */}
              </Col>
              {/* <Col lg={4} sm={12} align={"right"}>
                <a
                  href="https://www.facebook.com/scdistributorsllc/"
                  target="new"
                >
                  <Image src="assets/FB.png" fluid />
                </a>
                &nbsp;
                <a
                  href="https://www.linkedin.com/company/sc-distributors/"
                  target="new"
                >
                  <Image src="assets/linkedin.png" />
                </a>
              </Col> */}
            </Row>
          </div>
        </div>
        <PrivacyModal show={this.state.privacyModalShow} onHide={modalClose} />
        <TermsModal show={this.state.termsModalShow} onHide={termsModalClose} />
      </>
    );
  }
}
export default Footer;
