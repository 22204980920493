import React from "react";
import ManagementProfile from "../ManagementProfile/ManagementProfile";
import image from "../../assets/team/JimCondon.jpg";

const JimCondonBio = () => {
  const name = "JIM CONDON, MBA, CIMA®";
  const title = "Chief Operating Officer, StratCap";
  const bio = `Jim Condon joined StratCap in 2010 and currently serves as Managing Partner of StratCap, President of StratCap Investment Management LLC, President of Strategic Datasphere, LLC, President of StratCap Digital Infrastructure Advisors II, LLC, and President of MainStreet Digital Broadband, LLC. Mr. Condon also serves as Chief Operating Officer of StratCap Securities, LLC, and is a Director and board member of Pegasus Digital Mobility Acquisition Company. In his various roles, Mr. Condon is primarily responsible for overseeing the operations, investment, capital markets, and asset management activities for each company. Mr. Condon is also a member of the investment committees and board of directors for each of StratCap’s sponsored private and public investment programs, including Strategic Wireless Infrastructure Fund, Strategic Data Center Fund, Strategic Datasphere, and MainStreet Digital Broadband. He also serves as Chairman of the Board for Strategic Wireless Infrastructure Fund II, LLC.`;
  const bio2 = `Mr. Condon has over 20 years of experience in investment management within the financial services industry. Mr. Condon joined StratCap from the Capital Group, American Fund out of southern California. Prior to that Mr. Condon was at Schroder Investment Management based in New York City. Early in his career Mr. Condon worked for Refco Alternative Investments, the hedge fund division of Refco. Throughout his career, Mr. Condon has worked with REITs, SPAC’s, infrastructure, managed futures, hedge funds, renewable energy, social impact investing, equity and fixed income vehicles and private placements.`;
  const bio3 = `Mr. Condon holds an MBA., cum laude, from the University of Notre Dame, a BS in finance from Park School of Business at Ithaca College, and a CIMA from the Wharton School at The University of Pennsylvania.`;

  return (
    <ManagementProfile
      name={name}
      title={title}
      image={image}
      bio={bio}
      bio2={bio2}
      bio3={bio3}
    />
  );
};

export default JimCondonBio;
