import React, { Component } from "react";
import NavbarPages from "../../Containers/NavbarPages";
import { Row, Col, Image, CardDeck, Card } from "react-bootstrap";
import assetsHero from "../../assets/images/assets_hero.jpg";
import chicago from "../../assets/images/CHICAGO-DC.jpg";
import dcBottom from "../../assets/images/DATACENTER-BOTTOM.jpg";
import hcTop from "../../assets/images/HEALTHCARE-TOP.jpg";
import hcBottom from "../../assets/images/HEALTHCARE-BOTTOM.jpg";
import renTop from "../../assets/images/RENEWABLE-TOP.jpg";
import renBottom from "../../assets/images/RENEWABLE-BOTTOM.jpg";
import iiTop from "../../assets/images/IMPACTINVESTING-TOP.jpg";
import iiBottom from "../../assets/images/IMPACTINVESTING-BOTTOM.jpg";
import mmTop from "../../assets/images/MIDDLEMARKET-TOP.jpg";
import "./Asset.css";

class AssetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <NavbarPages />
        <div className="assets_hero_container">
          <div className="assets_adjustment_layer">
            <Image src={assetsHero} fluid className="asset_hero_image" />
          </div>
          <div className="assets_hero_text">Asset Spotlights</div>
        </div>
        <Row className="assets_container">
          <Col lg={12} sm={12} align={"center"}>
            <p className="asset_header_para">
              StratCap Securities has raised nearly $5 billion in investor
              capital for a variety of offerings whose underlying assets have
              included commercial real estate, wireless infrastructure, U.S.
              middle-market debt, renewable energy infrastructure and global
              credit impact investing.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} align={"center"} className="div_1">
            <p className="assets_dc_header">data center real estate</p>
          </Col>
          <Col lg={6} sm={12} className="div_2">
            {/* cards */}
            <CardDeck className="asset_cards">
              <Card>
                <div className="card_image_height_dc">
                  <Card.Img variant="top" src={chicago} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Chicago Data Center
                  </Card.Title>
                  <Card.Text>
                    The Chicago Data Center is a 250,000 square foot
                    multi-tenant facility, designed for a broad range of
                    customers with diverse computing and power density needs.
                    This property, that sits in a desirable location and
                    includes long term leases with high-quality tenants, was
                    purchased for $212 million in May of 2014. An additional
                    $36.3 million was then invested into the property, before it
                    was sold in December of 2017.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <div className="card_image_height_dc">
                  <Card.Img variant="top" src={dcBottom} />
                </div>
                <Card.Body>
                  <Card.Title>MAPLETREE PORTFOLIO (14 properties)</Card.Title>
                  <Card.Text>
                    The Mapletree Portfolio includes 14 data center properties
                    that comprise more than 2.3 million square feet of net
                    leasable space across 9 states. Among its 15 tenants are
                    Fortune Global 500 and NYSE/Nasdaq-listed companies
                    including Hewlett-Packard, AT&T, The Vanguard Group, General
                    Electric, Level 3 Communications and Equinix. This portfolio
                    was assembled between 2011 and 2014 for $653 million and
                    later sold in late 2017.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
          <Col lg={12} sm={12} align={"center"} className="div_3" />
        </Row>

        <Row>
          <Col lg={12} sm={12} align={"center"} className="div_4">
            <p className="assets_dc_header">healthcare real estate</p>
          </Col>
          <Col lg={6} sm={12} className="div_5">
            <CardDeck className="asset_cards">
              <Card>
                <div className="card_image_height_hcr">
                  <Card.Img variant="top" src={hcTop} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Baylor Surgical Hospital at Fort Worth
                  </Card.Title>
                  <Card.Text>
                    The Baylor Surgical Hospital is a specialty hospital built
                    in 2014, measuring approximately 83,000 square feet, and was
                    acquired for over $48 million. This facility is 100% leased
                    to Fort Worth Surgicare Partners, Ltd, and is located in the
                    fourth largest metropolitan area in the country.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <div className="card_image_height_hcr">
                  <Card.Img variant="top" src={hcBottom} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    HPI Portfolio (Consists of 9 separate assets)
                  </Card.Title>
                  <Card.Text>
                    The HPI Portfolio, located in and around Oklahoma City, OK
                    consists of two acute care hospitals and seven integrated
                    medical facilities totaling over 327,000 square feet of
                    rentable area. This 100% occupied portfolio was purchased
                    between December 2015 and June 2016 for $116.5 million.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
          <Col lg={12} sm={12} align={"center"} className="div_6"></Col>
        </Row>

        <Row>
          <Col lg={12} sm={12} align={"center"} className="div_7">
            <p className="assets_dc_header">renewable energy</p>
          </Col>
          <Col lg={6} sm={12} className="div_8">
            {/* cards */}
            <CardDeck className="asset_cards">
              <Card>
                <div className="card_image_height_re">
                  <Card.Img variant="top" src={renTop} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Raleigh Portfolio
                  </Card.Title>
                  <Card.Text>
                    The Raleigh Portfolio is located throughout eastern North
                    Carolina, consists of 5 separate ground mounted solar
                    photovoltaic systems and was acquired for $20,500,000. This
                    portfolio produces over 27,000 kWh annually, producing
                    enough electricity to power roughly 5,000 homes per year.
                    Duke Energy, one of the largest utilities, is the offtaker
                    and has a power purchase agreement of more than 10 years.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <div className="card_image_height_re">
                  <Card.Img variant="top" src={renBottom} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Greenfield Wind
                  </Card.Title>
                  <Card.Text>
                    The Greenfield Wind facility is located in Teton County, MT,
                    and consists of 13 General Electric wind turbines that
                    produce over 93,000,000 kWh per year, which is enough to
                    power over 5,500 homes per year. It was acquired for
                    $34,773,000 and sells power directly to investment grade
                    utility company, NorthWestern Energy.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
          <Col lg={12} sm={12} align={"center"} className="div_9"></Col>
        </Row>

        <Row>
          <Col lg={12} sm={12} align={"center"} className="div_10">
            <p className="assets_dc_header">impact investing</p>
          </Col>
          <Col lg={6} sm={12} className="div_11">
            {/* cards */}
            <CardDeck className="asset_cards">
              <Card>
                <div className="card_image_height_ii">
                  <Card.Img variant="top" src={iiTop} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Agricultural Supplies Producer – South Africa
                  </Card.Title>
                  <Card.Text>
                    This investment represents a $10 million senior secured
                    trade finance facility to an agricultural supplies producer
                    in South Africa that produces and distributes specialized
                    fertilizer. The increase in crop growth and improved general
                    soil health due to the fertilizer is expected to provide
                    higher quality food and in turn greater nutritional value.
                    In support of this shift towards food with higher
                    nutritional value, the borrower has also implemented a
                    proprietary technical assistance program for its customers
                    that focuses on a more scientific and biologically
                    sustainable approach to soil utilization.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <div className="card_img_container">
                  <div className="card_image_height_ii">
                    <Card.Img variant="top" src={iiBottom} />
                  </div>
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Electronics Retailer - Indonesia
                  </Card.Title>
                  <Card.Text>
                    This investment represents a $5 million term loan to an
                    electronics retailer in Indonesia who caters to consumers in
                    the lower-middle to lower-income brackets. Since many of its
                    customers lack access to traditional forms of credit such as
                    credit cards, the retailer allows its customers to purchase
                    household appliances like refrigerators and washing machines
                    on payment plans. The term loan allowed the retailer to
                    significantly increase the number of customers to which it
                    can provide financing in the coming years.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
          <Col lg={12} sm={12} align={"center"} className="div_12"></Col>
        </Row>

        <Row>
          <Col lg={12} sm={12} align={"center"} className="div_13">
            <p className="assets_dc_header">US MIDDLE MARKET DEBT</p>
          </Col>
          <Col lg={4} sm={12} className="div_14">
            {/* cards */}
            <CardDeck className="asset_cards asset_card_solo">
              <Card>
                <div className="card_image_height_mm">
                  <Card.Img variant="top" src={mmTop} />
                </div>
                <Card.Body>
                  <Card.Title className="cap_letters">
                    Sizzling Platter, LLC
                  </Card.Title>
                  <Card.Text>
                    This $15 million direct originated, senior secured loan
                    helped Sizzling Platter, LLC expand its management company.
                    The organization now includes over 215 restaurants including
                    Little Caesars, Sizzler, Dunkin Donuts, and Red Robin brands
                    among other franchises in the western U.S.
                  </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
          <Col lg={12} sm={12} align={"center"} className="div_15"></Col>
        </Row>
        <Row>
          <Col lg={12} sm={12} align="center">
            <p className="asset_disclosure">
              Above are examples of assets, acquired by various investment
              managers and included in different offerings, that were
              distributed by StratCap Securities. The assets represented are from
              closed offerings that are no longer accepting new investors. These
              examples are shown for illustrative purposes only and are not
              indicative of assets purchased by existing offerings.
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

export default AssetPage;
