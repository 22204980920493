import React from "react";
// import Navibar from "../Containers/Navibar";
import Navigation from "./Navigation";
// import NavbarPages from "./NavbarPages";
// import Distribution from "../Components/Distribution/Distribution";
// import Leverage from "../Components/Leverage/Leverage";
// import Hero from "../Components/Hero/Hero";
// import Blurb from "../Components/Blurb/Blurb";
// import { BrowserView, MobileView } from "react-device-detect";
// import throttle from 'lodash.throttle';

const Home = () => {
  return (
    <>
      {/* <Navibar /> */}
      <Navigation />
      {/* <Hero />
      <Blurb />
      <Distribution />
      <Leverage /> */}
    </>
  );
};
export default Home;
