import React from "react";
import NavbarPages from "../../Containers/NavbarPages";
// import GoogleMap from "../Map/GoogleMap";
import { Row, Col } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <>
      <NavbarPages />
      <Row>
        <Col lg={12} className="contact_map_nopadding">
          <div className="contact_map_container_2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2349.2900370653474!2d-117.92647620412934!3d33.62092532535506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd200795afd1ed%3A0x530653e145a53df1!2s3101%20West%20Coast%20Hwy%20%23140%2C%20Newport%20Beach%2C%20CA%2092663!5e0!3m2!1sen!2sus!4v1646901103013!5m2!1sen!2sus"
              title="scMap"
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen
              loading="lazy"
            ></iframe>
          </div>
        </Col>
      </Row>
      <div className="container contact_container_2">
        <Row className="contact_margin">
          <Col lg={12} align={"center"}>
            <h1 className="contact_blue_text">Contact Us</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} align={"center"}>
            <h3>StratCap Securities</h3>
          </Col>
        </Row>
        <Row>
          <Col lg={12} align={"center"}>
            <p>
              Main Office: <a href="tel:949-681-9125">949-681-9125</a> <br />
              Financial Advisors: <a href="tel:877-907-1148">(877) 907-1148</a>
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContactUs;
