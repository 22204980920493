// Dependencies
import React from "react";
import { CardDeck, Row, Image } from "react-bootstrap";

// Components
import NavbarPages from "../../Containers/NavbarPages";
import EmpCard from "../EmpCard/EmpCard";

// Assets
import patM from "../../assets/team/PatMiller.jpg";
import jimC from "../../assets/team/JimCondon.jpg";
import robB from "../../assets/team/RobBruce.jpg";
import jimK from "../../assets/team/JimKearny.jpg";
import erikRo from "../../assets/team/ErikR.jpg";

import managementHero from "../../assets/images/management.png";

// CSS
import "./Management.css";

const Management = () => {
  const patText =
    "Patrick J. Miller is a Co-Founder and Managing Partner of StratCap and President of StratCap Securities, LLC...";
  const jimText =
    "Jim Condon joined StratCap in 2010 and currently serves as Managing Partner of StratCap...";
  const robText =
    "Mr. Bruce has over 23 years of diverse financial services sales and marketing experience with...";
  const kearnyText =
    "Jim has been with StratCap Securities since October of 2010 and is its Chief Compliance Officer...";
  const erikText =
    "With 15 years of experience, Erik has been involved in over $15B in alternative investment offerings...";

  const infoArray = [
    {
      image: patM,
      name: "Patrick Miller",
      title: "President",
      text: patText,
      link: "/PatrickMiller",
    },
    {
      image: jimC,
      name: "Jim Condon, MBA, CIMA",
      title: "Chief Operating Officer",
      text: jimText,
      link: "/JimCondon",
    },
    {
      image: robB,
      name: "Robert Bruce",
      title: "Chief Marketing Officer",
      text: robText,
      link: "/RobertBruce",
    },
    {
      image: jimK,
      name: "James R. Kearny, MBA",
      title: "Chief Compliance Officer",
      text: kearnyText,
      link: "/JimKearny",
    },
    {
      image: erikRo,
      name: "erik rostvold",
      title: "svp - product management",
      text: erikText,
      link: "/ErikRostvold",
    },
  ];

  const cardMakerFirst = infoArray.slice(0, 2).map((items, i) => {
    return (
      <EmpCard
        key={i}
        image={items.image}
        name={items.name}
        title={items.title}
        text={items.text}
        link={items.link}
      />
    );
  });
  const cardMakerSecond = infoArray.slice(2, 4).map((items, i) => {
    return (
      <EmpCard
        key={i}
        image={items.image}
        name={items.name}
        title={items.title}
        text={items.text}
        link={items.link}
      />
    );
  });
  const cardMakerThird = infoArray.slice(4, 6).map((items, i) => {
    return (
      <EmpCard
        key={i}
        image={items.image}
        name={items.name}
        title={items.title}
        text={items.text}
        link={items.link}
      />
    );
  });
  return (
    <>
      <NavbarPages />
      <div className="management_hero_container">
        <div className="management_adjustment_layer">
          <Image src={managementHero} fluid className="management_hero_img" />
        </div>
        <div className="management_hero_text">Management team</div>
      </div>
      <div className="management-grid">
        {infoArray.map((items, i) => {
          return (
            <EmpCard
              key={i}
              image={items.image}
              name={items.name}
              title={items.title}
              text={items.text}
              link={items.link}
            />
          );
        })}
      </div>
    </>
  );
};

export default Management;
