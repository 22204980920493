import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Navibar from "../../Containers/NavbarPages";
import "./ManagementProfile.css";

const ManagementProfile = props => {
  return (
    <>
      <Navibar />
      <div className="container profile_top_margin">
        <Row className="profile_image_row">
        <Col sm={12} className="profile_image_container">
          <Image src={props.image} rounded fluid alt="Responsive image" />
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="profile_no_padding">
            <h3 className="profile_header">{props.name.toUpperCase()}</h3>
          </Col>
          <Col lg={12} className="profile_no_padding">
            <p className="profile_title">{props.title.toUpperCase()}</p>
            <p>{props.bio}</p>
            <p>{props.bio2}</p>
            <p>{props.bio3}</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ManagementProfile;
