import React from "react";
// import { Row } from "react-bootstrap";
import Row from "react-bootstrap/Row"
import "./Blurb.css";

const Blurb = () => {
  return (
    <div className="container">
      <Row className="blurb_div">
        <p className="blurb_info">
          StratCap is a premier financial services organization that has
          raised over $5 billion for a variety of alternative investment
          offerings. Our goal is to provide financial advisors and their clients
          access to innovative investment structures and asset classes.
        </p>
        <p className="blurb_info_bottom">
          <i>
            We currently offer dynamic private investments focused on digital
            infrastructure assets, through our affiliated product sponsor{" "}
          </i>
        </p>
        <p className="blurb_info_bottom_link">
          <i>
            <a
              href="http://strategiccapitalfundmanagement.com/"
              id="blurb-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              Strategic Capital Fund Management.
            </a>
          </i>
        </p>
      </Row>
    </div>
  );
};

export default Blurb;
