import React from "react";
import ManagementProfile from "../ManagementProfile/ManagementProfile";
import image from "../../assets/team/PatMiller.jpg";

const PatMillerBio = () => {
  const name = "Patrick Miller";
  const title = "President, StratCap";
  const bio = `Patrick J. Miller is a Co-Founder and Managing Partner of StratCap and President of StratCap Securities, LLC – StratCap's managing broker/dealer. He is also a Director and board member of Pegasus Digital Mobility Acquisition Company. Mr. Miller is responsible for overall strategy, new business development and product marketing. In addition, Mr. Miller has served on the Board of Trustees for the Investment Program Association, the leading non-profit trade association for the direct investments industry.`;
  const bio2 = `Prior to co-founding Stratcap, Mr. Miller served as President of KBS Capital Markets Group, LLC from the company’s inception in October 2005. During Mr. Miller’s tenure, KBS Capital Markets Group raised approximately $2.3 billion in investment capital for KBS Real Estate Investment Trust, Inc. and KBS Real Estate Investment Trust II, Inc. Prior to joining KBS Capital Markets Group, Mr. Miller served as President.
  Mr. Miller began his career as a Wholesaler with American Funds Distributors. Mr. Miller received a Bachelor of Arts from the University of California at Los Angeles.`;

  return (
    <ManagementProfile
      name={name}
      title={title}
      image={image}
      bio={bio}
      bio2={bio2}
    />
  );
};

export default PatMillerBio;
