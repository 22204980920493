import React from "react";
import "./JobPages.css";
import scLogo from "../../assets/images/SC.jpg";
import { Row, Col, Container, Image } from "react-bootstrap";
import NavbarPages from "../../Containers/NavbarPages";
import careerHero from "../../assets/images/career_hero.jpg";

const SeniorAccountant = () => {
  return (
    <>
      <NavbarPages />
      <div className="career_hero_container">
        <div className="career_adjustment_layer">
          <Image src={careerHero} fluid />
        </div>
        <div className="hero_text">Come Work With Us</div>
      </div>
      <Container>
        <Row className="jobs_container">
          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Image src={scLogo} className="specialist_logo" />
              </Col>
              <Col
                lg={6}
                sm={12}
                className="specialist_resume_button_container"
              >
                <a
                  className="btn-u"
                  href="mailto:career@scdistributors.com?Subject=Senior%20Accountant"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apply with Resume
                </a>
              </Col>
            </Row>
            <h2 className="jobs_bold jobs_jobtitle_margin">
              Senior Accountant
            </h2>
            <p>at StratCap</p>
            <p>Costa Mesa, CA - Sales</p>
            <p>Job Type: Full-time</p>

            <p>This Position Reports To: Mario Pacheco, SVP Finance</p>
            <h4 className="jobs_bold">Summary of Responsibilities</h4>
            <p>
              The position is responsible for the timely and accurate monthly
              financial reporting. Also responsible for preparing and posting
              daily, weekly and monthly journal entries to the general ledger
              based on Generally Accepted Accounting Principles, reconcile bank
              accounts, analyze/adjust various account rec schedules, as needed,
              and special projects as required.
            </p>
            <h4 className="jobs_bold">Primary Responsibilities</h4>
            <ul>
              <li>
                Assist in a managing the timely and accurate month-end financial
                reporting processes.
              </li>
              <li>
                Prepare monthly, quarterly and annual consolidated financial
                statements.{" "}
              </li>
              <li>
                Analyze/reconcile various balance sheet and income statement
                accounts on a monthly/quarterly basis and prepare any correcting
                entries, if needed.
              </li>
              <li>
                Preparing daily financial activities including preparing and
                posting of daily, weekly and monthly journal entries and
                researching & resolving financial issues.
              </li>
              <li>
                Prepare tracking schedules for commissions, fixed assets,
                sponsorships, underwriting compensation, etc.{" "}
              </li>
              <li>
                Assist in processing payroll and related quarterly and annual
                reporting.
              </li>
              <li>
                Preparation of tax support schedules and coordinate annual tax
                filing & reporting with third party tax firm to the various
                states.
              </li>
              <li>Prepare required monthly and quarterly FINRA reports.</li>
              <li>
                Support and maintain the accounting software – YARDI as the
                administrator.
              </li>
              <li>Assist in the preparation of the annual budget.</li>
              <li>Special projects, as assigned and overtime, as required.</li>
              <li>Prepare Cash Flows YTD and Forecast.</li>
              <li>
                Manage state foreign qualification filings, registrations and
                withdrawals with third party registered agent.
              </li>
            </ul>
            <h4 className="jobs_bold">Required Competencies</h4>
            <ul>
              <li>
                Bachelor’s Degree in Accounting with a minimum of three years of
                experience. Prefer CPA or CPA candidate with Big 4 audit
                experience.
              </li>
              <li>Excellent written and verbal communication skills.</li>
              <li>
                Proficient using Microsoft Outlook, Excel and Word. YARDI or MRI
                experience preferred.
              </li>
              <li>
                Excellent organizational skills with the ability to multi-task
                and provide statuses and follow ups.
              </li>
              <li>
                Must have a positive attitude and ability to work in a dynamic
                and fast paced environment. Overtime required.
              </li>
            </ul>
            <h4 className="jobs_bold">
              Strategic Capital Management Team Experience
            </h4>
            <p>
              Over 75 years of combined experience in wholesale distribution
              management. Helped raise over $20 billion in equity capital over
              the last 15 years.. Successful in distributing Business
              Development Companies, Non-Traded REITs, Reg D private placement
              offerings and LLC’s .{" "}
            </p>
            <h4 className="jobs_bold">About StratCap</h4>
            <p>
              Strategic Capital’s goal is to be the premier distribution
              platform for select alternative investment products. Our mission
              is to connect investors and financial advisors with a focused
              group of leading alternative investment managers. Through a
              demonstrated financial services wholesale distribution model, we
              deliver these products to financial advisors (and their clients)
              throughout the United States.
            </p>
            <p>
              Our leadership's extensive experience in wholesale distribution,
              combined with our strategy to engage a prominent real estate
              investment banking organization, helped enable us to identify and
              evaluate the capabilities, backgrounds, financial sustainability
              and track records of a wide variety of potential investment
              managers. Finally, our leadership's extensive distribution
              experience and our proprietary SCORE (Strategic Capital Online
              Reporting Engine) technology platform helps us to effectively and
              efficiently track and manage each step of the product delivery
              process for our financial advisor and broker/dealer partners.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SeniorAccountant;
