import React from "react";

const BdRelationships = (props) => {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 200 200"
        xmlSpace="preserve"
      >
        <g className={props.circleChange}>
          <path
            className="st0"
            d="M100,32.42c37.26,0,67.58,30.32,67.58,67.58s-30.32,67.58-67.58,67.58S32.42,137.26,32.42,100
		S62.74,32.42,100,32.42 M100,26.42c-40.64,0-73.58,32.94-73.58,73.58s32.94,73.58,73.58,73.58s73.58-32.94,73.58-73.58
		S140.64,26.42,100,26.42L100,26.42z"
          />
        </g>
        <g className={props.imageChange}>
          <path
            class="st0"
            d="M95.03,110.32c1.57-2.04,5.29-2.97,7.61-3.37v-1.1c-0.4-0.65-0.75-1.27-0.99-1.77
	c-1.18-0.63-2.57-1.75-2.77-3.54c-0.17-1.56,0.31-2.6,0.81-3.25c-0.66-4.74,1.06-6.64,2.95-8.14v-4.79h5.24v1.99
	c1.19-0.24,2.15-0.27,2.35-0.27c0.18,0,0.91,0.02,1.87,0.18v-6.09H98.48V71.9h0.96v-1.29h-0.96v-0.03H74.41v0.03h-0.96v1.29h0.96
	v8.26H60.79v34.63h-2.57v2.63h34.33C93.17,113.77,93.85,111.85,95.03,110.32z M70.25,107.03H65v-5.24h5.24V107.03z M70.25,98.31H65
	v-5.24h5.24V98.31z M70.25,89.6H65v-5.24h5.24V89.6z M87.8,74.54h5.24v5.24H87.8V74.54z M87.8,83.26h5.24v5.24H87.8V83.26z
	 M87.8,91.97h5.24v5.24H87.8V91.97z M79.84,74.54h5.24v5.24h-5.24V74.54z M79.84,83.26h5.24v5.24h-5.24V83.26z M79.84,91.97h5.24
	v5.24h-5.24V91.97z M80.59,115.1v-11.7h11.7v11.7H80.59z M114.67,117.43h-0.15c0.05-0.19,0.1-0.37,0.15-0.58V117.43z M120.97,123.42
	h-4.39v-1.36h4.39V123.42z M126.79,126.25c-1.08-9.04-1.7-12.06-3.08-13.86c-1.39-1.81-7.09-2.56-7.09-2.56
	c-2.62,12.51-4.01,14.47-4.01,14.47l-1.23-11l0.46-0.9l-1.54-0.75l-1.54,0.75l0.46,0.9l-1.23,11c0,0-1.39-1.96-4.01-14.47
	c0,0-5.7,0.75-7.09,2.56c-1.39,1.81-2,4.82-3.08,13.86c0,0-0.92,3.16,1.85,3.16h14.64h14.64
	C127.71,129.41,126.79,126.25,126.79,126.25 M118,98.56c0,0,1.35-0.11,1.16,1.7c-0.11,1.06-2.2,1.81-2.2,1.81s-2.35,6.33-6.67,6.33
	c-4.32,0-6.67-6.33-6.67-6.33s-2.08-0.75-2.2-1.81c-0.19-1.81,1.16-1.7,1.16-1.7c-1.5-6.33,0.93-6.67,2.66-8.36
	c1.73-1.7,5.05-1.7,5.05-1.7s3.31,0,5.05,1.7C117.08,91.89,119.5,92.23,118,98.56 M123,98.17c0-8.39,7.09-8.88,7.09-8.88
	s7.09,0.49,7.09,8.88c0,0,0,4.74,2.12,5.6c0,0-2.24,1.1-5.72,0.97c0,0-1.49,2.31-3.48,2.31c-1.99,0-3.48-2.31-3.48-2.31
	c-3.48,0.12-5.72-0.97-5.72-0.97C123,102.92,123,98.17,123,98.17 M134.57,107.78c0,0,5.35,1.34,5.85,6.08
	c0.5,4.74,1.62,10.71,1.62,10.71s0.62,2.92-2.74,2.92h-10.2l-1.83-12.77c0,0-1.53-4.95-4.89-5.39c0,0,0.87-1.3,2.99-1.54
	c0,0-0.75,2.8,4.73,6.69C130.09,114.48,134.94,110.82,134.57,107.78"
          />
        </g>
      </svg>
    </>
  );
};

export default BdRelationships;
